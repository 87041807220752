import React from 'react'
import Seo from '../../components/Seo'
import FullscreenLandingSection from '../../components/FullscreenLandingSection'
import Fade from 'react-reveal/Fade'
import Slider from '../../components/Slider'
import IndividualContactSection from '../../components/IndividualContactSection'
import ContactCard from '../../components/ContactCard'
import DefaultSection from '../../components/DefaultSection'
import NavigationLink from '../../components/NavigationLink'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="jQAssistant"
           description="Nachhaltige Softwareprojekte mit jQAssistant" />

      <FullscreenLandingSection className="bg-gradient-orange">
        <Slider showNavButtons={false}>
          <div className="media-slide-item-alpha title-desc mb-0 px-1 text-left">
            <div>
              <Fade top>
                <h1 className="home-title display-title">
                <span className="text-condensed text-uppercase">3&nbsp;Entwickler, 5&nbsp;Meinungen, 8&nbsp;Ideen, einer
                  hat den Überblick.
                </span>
                </h1>
              </Fade>
              <Fade right>
                <h1 className="home-title display-title mb-4">jQAssistant</h1>
              </Fade>
            </div>
          </div>
          <div className="media-slide-item-alpha title-desc mb-0 px-1 text-left">
            <div>
              <div>
                <h2>In aller Offenheit:</h2>
              </div>
              <div className="display-decor">
                <h1 className="mb-5">
                  <span className="font-italic">Wir haben die Glaskugel gegen jQAssistant eingetauscht.</span>
                </h1>
                <h1 className="mb-4">
                  <span className="font-italic">Durch jQAssistant haben wir eine neue Option, unser System zu bewerten und zukunftssicher weiterzuentwickeln.</span>
                </h1>
              </div>
            </div>
          </div>
        </Slider>
      </FullscreenLandingSection>

      <FullscreenLandingSection className="bg-black text-white" menuInverted logoInverted>
        <div className="title-desc mb-0 text-left">
          <h2 className="text-white-50">
            <Fade left>Nachhaltigkeit</Fade>
          </h2>
          <Fade bottom>
            <h1 className="home-title display-title mb-4 text-warning">
              <span>Wir können es uns nicht leisten, aller 10 Jahre das System neu zu entwickeln.</span>
            </h1>
          </Fade>
          <div style={{ 'margin-top': '8em' }}>
            <Fade bottom cascade>
              <div className="my-5 font-italic text-white-50">
                <h1>
                  <span>Durch jQAssistant haben wir eine neue Option, unser System zu bewerten und zukunftssicher weiterzuentwickeln.</span>
                </h1>
              </div>
              <div className="mb-5 font-italic">
                <h1>
                  <span>Die Entscheidung, unsere bestehende Geschäftsanwendung nicht neu zu entwickeln, sondern planvoll zu modernisieren, war die richtige.</span>
                </h1>
              </div>
              <div className="mb-5 font-italic text-white-50">
                <h1>
                  <span>jQAssistant hat uns erlaubt, die Komplexität unseres Systems zu verstehen.</span>
                </h1>
              </div>
            </Fade>
          </div>
        </div>
      </FullscreenLandingSection>

      <FullscreenLandingSection className="bg-level-1">
        <div className="title-desc mb-0 text-right">
          <h2>
            <Fade right>Ressourcen</Fade>
          </h2>
          <Fade bottom>
            <h1 className="home-title display-title mb-4 text-danger">
              <span>Fangen wir doch endlich an, unsere <span
                className="d-none d-md-block">Entwicklerressourcen</span><span
                className="d-sm-block d-md-none">Entwickler-ressourcen</span> effektiv einzusetzen.</span>
            </h1>
          </Fade>
          <div style={{ 'margin-top': '8em' }}>
            <Fade bottom cascade>
              <div className="my-5 font-italic text-black-50">
                <h1>
                  <span>Mit dem Einsatz von jQAssistant steigt die Produktivität erstmals im gleichen Maße wie der Zuwachs an Entwicklerressourcen.</span>
                </h1>
              </div>
              <div className="mb-5 font-italic">
                <h1>
                  <span>Seit wir jQAssistant einsetzen, schaffen wir die Arbeit und machen unsere Kunden glücklich.</span>
                </h1>
              </div>
            </Fade>
          </div>
        </div>
      </FullscreenLandingSection>

      <DefaultSection className="bg-gradient-orange text-black" menuInverted logoInverted>
        <div className="mb-5 font-italic">
          <h1 className="text-white">
            <span><i className="icon icon-github"></i> Für Entwickler</span>
          </h1>
        </div>
        <div className="mb-5 font-italic" style={{ 'margin-top': '4em' }}>
          <h1>
            <span>Dokumentation, Tutorials, Plugins und vieles mehr finden Sie unter jQAssistant auf <NavigationLink
              to={'https://jqassistant.org'} ariaLabel={'jQAssistant Webseite für Entwickler'} asTextLink
              className="text-white">GitHub</NavigationLink></span>.
          </h1>
        </div>
      </DefaultSection>

      <IndividualContactSection personKey={'dirk'} title={''} bold />
      <IndividualContactSection personKey={'stephan'} title={''} bold />
      <DefaultSection>
        <ContactCard title={'Wir unterstützen Sie!'} bold />
      </DefaultSection>
    </React.Fragment>
  )
}

export default Page
